import { FC } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ChartCentreProps } from "./type";

export const PieChart: FC<ChartCentreProps> = ({
  categories,
  series,
  title,
}) => {
  const options = {
    chart: { type: "pie" },
    ...(title && { title: { text: title, align: "left" } }),
    // subtitle: { text: "My subtitle", align: "left" },
    xAxis: { categories },
    yAxis: { title: { text: "My y-axis" }, min: 0 },
    tooltip: { valueSuffix: "%" },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          { enabled: true, distance: 20 },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: { fontSize: "1.2em", textOutline: "none", opacity: 0.7 },
            filter: { operator: ">", property: "percentage", value: 10 },
          },
        ],
      },
    },
    series,
    // series: [
    //   {
    //     name: "Percentage",
    //     colorByPoint: true,
    //     data: [
    //       { name: "A", y: 61.41 },
    //       { name: "B", y: 11.84 },
    //       { name: "C", y: 10.85 },
    //     ],
    //   },
    // ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
