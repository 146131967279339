import { FC, useMemo } from "react";

import { VisualizationTableColumn, VisualizationValueType } from "./type";
import { Table } from "../Table";

type TabTableProps = {
  dataSource: any[];
  height?: number | string;
};

export const TabTable: FC<TabTableProps> = ({ dataSource, height }) => {
  const columns = useMemo(() => {
    return Object.entries(dataSource?.[0] || {}).map(([key, value]) => {
      let type = typeof value;

      if (!!Number(`${value}`.replaceAll(",", ""))) type = "number";

      return {
        key,
        type: type as VisualizationValueType,
      } as VisualizationTableColumn;
    });
  }, [dataSource]);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      height={height}
      // showIndex
    />
  );
};
