import _ from "lodash";

import {
  VisualizationAggregateType,
  VisualizationChartType,
  VisualizationField,
} from "../type";

type UseChartDataSource = {
  chartType: VisualizationChartType;
  dataSource: any[];
  xAxisOptions: VisualizationField[];
  yAxisOptions: VisualizationField[];
};

const transformValue = (value: any, type: string) => {
  switch (type) {
    case "number":
      return Number(`${value}`.replaceAll(",", ""));
    case "date":
    default:
      return value;
  }
};

const getName = (obj: Record<string, string | number>, key: string) => {
  const v = obj?.[key];

  return typeof v === "string" ? v.trim() : `${v}`;
};

export const useChartDataSource = ({
  chartType,
  dataSource,
  xAxisOptions,
  yAxisOptions,
}: UseChartDataSource) => {
  // NOTE: If there is no xAxisOptions, return empty categories and series
  if (!xAxisOptions?.length || !yAxisOptions?.length)
    return { categories: [], series: [] };

  const isOneXAxis = xAxisOptions.length === 1;
  const categoryKey = xAxisOptions[0].key;
  const seriesKey = xAxisOptions?.[1]?.key || categoryKey;
  const yAxisKey = yAxisOptions[0].key;
  const yAxisType = yAxisOptions[0].type;
  const yAxisAggregateType = yAxisOptions[0]?.aggregateType || "count";
  const categories = [] as string[];

  const getYAxisData = (
    data: any[],
    yAxisKey: string,
    yAxisType: string,
    aggregateType: VisualizationAggregateType
  ) => {
    try {
      const getValue = (v: any) => transformValue(v[yAxisKey], yAxisType);

      switch (aggregateType) {
        case "sum":
          return _.sumBy(data, getValue);
        case "min":
          return transformValue(_.minBy(data, getValue)[yAxisKey], yAxisType);
        case "max":
          return transformValue(_.maxBy(data, getValue)[yAxisKey], yAxisType);
        case "avg":
          return _.meanBy(data, getValue);
        case "count":
        default:
          return data.length;
      }
    } catch (e) {
      return data.length;
    }
  };

  const pieChartSeries = () => {
    const seriesData = _(dataSource)
      .groupBy(seriesKey)
      .map((g) => {
        const seriesName = getName(g[0], seriesKey);

        // NOTE: In case of pie chart, there is only one xAxis
        if (isOneXAxis) {
          return {
            name: seriesName,
            y: getYAxisData(g, yAxisKey, yAxisType, yAxisAggregateType),
          };
        }

        // NOTE: In case of pie chart, there are two xAxes
        const categoriesData = _(g)
          .groupBy(categoryKey)
          .map((g2) => {
            const categoryName = getName(g2[0], categoryKey);

            if (!categories.includes(categoryName))
              categories.push(categoryName);

            return {
              name: `${seriesName} - ${categoryName}`,
              y: getYAxisData(g2, yAxisKey, yAxisType, yAxisAggregateType),
            };
          })
          .value();

        return categoriesData;
      })
      .value()
      .flat();

    return { categories, series: [{ name: seriesKey, data: seriesData }] };
  };

  // NOTE: If there is pie chart
  if (chartType === "pie") return pieChartSeries();

  // NOTE: If there are two xAxes, group by seriesKey and categoryKey
  const seriesData = _(dataSource)
    .groupBy(seriesKey)
    .map((g) => {
      const seriesName = getName(g[0], seriesKey);

      if (isOneXAxis) {
        categories.push(seriesName);

        return {
          name: seriesName,
          y: getYAxisData(g, yAxisKey, yAxisType, yAxisAggregateType),
        };
      }

      return {
        name: seriesName,
        data: _(g)
          .groupBy(categoryKey)
          .map((g2) => {
            const categoryName = getName(g2[0], categoryKey);

            if (!categories.includes(categoryName))
              categories.push(categoryName);

            return {
              name: categoryName,
              y: getYAxisData(g2, yAxisKey, yAxisType, yAxisAggregateType),
            };
          })
          .value(),
      };
    })
    .value();

  if (seriesData?.[0]?.y)
    return {
      categories,
      series: [{ name: seriesKey, data: seriesData }],
    };

  return { categories, series: seriesData };
};
