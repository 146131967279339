import useAxios from "axios-hooks";
import { Visualization } from "../components/visualization/Visualization";

import { useMemo } from "react";
import { Breadcrumb } from "../components/layout/Breadcrumb";
// import { mock } from "../mock";

const endpoint = window.__env__.BASE_API_URL;

export const VisualizationPage = () => {
  const currUrl = window.location.href;
  const splited = currUrl.split("/");
  const resourceId = splited.pop();
  const resourceName = splited.pop();
  const [{ data, loading, error }] = useAxios(
    `${endpoint}?resource_id=${resourceId}`
  );

  // const { data, error, loading } = {
  //   data: mock,
  //   loading: false,
  //   error: null,
  // };

  // console.log("data", data);

  const { source, dict } = useMemo(() => {
    const fields = data?.result?.fields || [];
    const records = data?.result?.records || [];

    const dict = fields.map((field: any) => {
      return {
        attribute: field.id,
        type: field.type,
        desc: field?.description || "",
      };
    });

    return { source: records, dict };
  }, [data?.result]);

  if (loading) return <div></div>;
  if (error) {
    console.error("error", error);

    return <div>Error!</div>;
  }

  return (
    <>
      <Breadcrumb
        id={resourceId || ""}
        name={resourceName || ""}
        code={resourceName?.toLowerCase() || ""}
      />
      <Visualization
        dataSource={source}
        dataDict={dict}
        resourceName={resourceName || ""}
      />
      ;
    </>
  );
};
