import { useDraggable } from "@dnd-kit/core";

type DraggableProps = {
  children: React.ReactNode;
  id: string;
  className?: string;
};

export const Draggable = (props: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={props?.className}
    >
      {props.children}
    </button>
  );
};
