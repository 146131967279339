import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import { VisualizationPage } from "./pages/Visualization";
import { Header } from "./components/layout/Header";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navbar } from "./components/layout/Navbar";

exportingModule(Highcharts);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          a: "A",
        },
      },
      th: {
        translation: {
          a: "ก",
        },
      },
    },
    lng: "th", // if you're using a language detector, do not define the lng option
    fallbackLng: "th",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const { i18n } = useTranslation();
  const [cookie] = useCookies(["user_lang"]);

  useEffect(() => {
    i18n.changeLanguage(cookie.user_lang || "th");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Navbar />

      <div className="m-auto w-full bg-[#eee]">
        <VisualizationPage />
      </div>
    </>
  );
}

export default App;
