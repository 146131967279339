import { FC } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ChartCentreProps } from "./type";

export const ColumnChart: FC<ChartCentreProps> = ({
  categories,
  series,
  title,
}) => {
  const options = {
    chart: { type: "column" },
    ...(title && { title: { text: title, align: "left" } }),
    // subtitle: { text: "My subtitle", align: "left" },
    xAxis: { categories },
    yAxis: { title: { text: "My y-axis" }, min: 0 },
    tooltip: { valueSuffix: " units" },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
    series,
    // series: [
    //   {
    //     name: "My series",
    //     data: [100, 200, 300],
    //   },
    //   {
    //     name: "My second series",
    //     data: [300, 200, 100],
    //   },
    // ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
