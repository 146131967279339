import { FC } from "react";
import { Table } from "../../Table";

type DataDicTableProps = {
  dataDict: any[];
};

export const DataDicTable: FC<DataDicTableProps> = ({ dataDict }) => {
  const columns = [
    { key: "attribute", label: "Attribute" },
    { key: "type", label: "Data Type" },
    { key: "desc", label: "Description" },
  ];

  return <Table columns={columns} dataSource={dataDict} />;
};
