import clsx from "clsx";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export const ToggleFontSize = () => {
  const fontSizeItems = {
    sm: { fix: "16px", transform: "100%" },
    md: { fix: "20px", transform: "125%" },
    lg: { fix: "24px", transform: "150%" },
  };

  const { t } = useTranslation();
  const [cookie, setCookie, deleteCookie] = useCookies(["user_font_size"]);

  useEffect(() => {
    const el = document.documentElement;
    const fontSize = cookie.user_font_size || "md";

    el.style.fontSize = (fontSizeItems[fontSize as never] as any)?.transform;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptCookieFontSize = (size = "md") => {
    deleteCookie("user_font_size");
    setCookie("user_font_size", size);
    window.location.reload();
  };

  return (
    <>
      {Object.entries(fontSizeItems).map(([key, value]) => {
        let topSpace = "";

        if (key === "sm") topSpace = "pt-0.5";

        return (
          <div
            key={key}
            className={clsx(
              "h-full flex items-center cursor-pointer px-[8px] text-white",
              "hover:bg-[var(--sky-primary-hover)]",
              cookie.user_font_size === key && "bg-[var(--sky-primary-active)]",
              topSpace
            )}
            style={{ height: "40px", fontSize: value.fix }}
            onClick={() => acceptCookieFontSize(key)}
          >
            {t("a")}
          </div>
        );
      })}
    </>
  );
};
