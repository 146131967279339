import React, { FC } from "react";

import { TabTable } from "./TabTable";
import { TabGraph } from "./TabGraph";
import clsx from "clsx";
import { TabMap } from "./TabMap";

type VisualizationProps = {
  dataSource: any[];
  dataDict: any[];
  resourceName: string;
};

export const Visualization: FC<VisualizationProps> = ({
  dataSource,
  dataDict,
  resourceName,
}) => {
  const [checked, setChecked] = React.useState(1);

  const tabs = [
    {
      label: "Table",
      element: (
        <TabTable dataSource={dataSource} height="calc(100vh - 185px)" />
      ),
    },
    {
      label: "Graph",
      element: (
        <TabGraph
          dataSource={dataSource}
          dataDict={dataDict}
          resourceName={resourceName}
        />
      ),
    },
    {
      label: "Map",
      element: <TabMap dataSource={dataSource} />,
    },
  ];

  return (
    <div
      className={clsx(
        "m-auto ckan-md:w-[750px] ckan-lg:w-[970px] ckan-xl:w-[1180px]"
      )}
    >
      <div
        className={clsx(
          "w-full flex flex-col p-3 lg:p-6 bg-white mx-0 ckan-md:mx-4 border rounded-lg shadow-lg"
        )}
      >
        <div className="flex flex-col">
          <h1 className="font-bold mb-3 lg:mb-6 font-sukhumvit">
            Visualization
          </h1>

          <div role="tablist" className="tabs tabs-lifted bg-[#f6f6f6] pt-3">
            {tabs.map(({ element, label }, idx) => (
              <React.Fragment key={`${label}-${idx}`}>
                <input
                  type="radio"
                  name="visualization-tabs"
                  role="tab"
                  className="tab font-semibold font-sukhumvit text-[0.60rem]"
                  aria-label={label}
                  defaultChecked={idx === checked}
                  onChange={() => setChecked(idx)}
                />
                <div
                  role="tabpanel"
                  className={clsx(
                    "tab-content bg-base-100 border-t-base-300 p-3 sm:p-6",
                    idx === 0 && "overflow-auto"
                  )}
                >
                  {checked === idx && element}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
