import { FC } from "react";

import clsx from "clsx";

type DividerProps = {
  className?: string;
  vertical?: boolean;
};

export const Divider: FC<DividerProps> = ({ className, vertical }) => {
  if (vertical) {
    return <div className={clsx("border-l m-2", className)} />;
  }

  return <div className={clsx("divider", className)} />;
};
