import { DragEndEvent } from "@dnd-kit/core";
import { VisualizationField } from "../type";
import { arrayMove } from "@dnd-kit/sortable";

type InputAction = "x-axis" | "y-axis";
type Action = InputAction | "fields";
type UseDragEndProps = {
  search: string;
  allFields: VisualizationField[];
  setAllFields: (fields: VisualizationField[]) => void;
  searchedFields: VisualizationField[];
  setSearchedFields: (fields: VisualizationField[]) => void;
  xAxis: VisualizationField[];
  setXAxis: (xAxis: VisualizationField[]) => void;
  yAxis: VisualizationField[];
  setYAxis: (yAxis: VisualizationField[]) => void;
};

export const useDragEnd = ({
  search,
  allFields,
  setAllFields,
  searchedFields,
  setSearchedFields,
  xAxis,
  setXAxis,
  yAxis,
  setYAxis,
}: UseDragEndProps) => {
  const inputIds = ["x-axis", "y-axis"] as InputAction[];

  // NOTE: Filter the fields based on the search input
  const withSearchFilter = (fields: VisualizationField[]) =>
    fields.filter((field) =>
      field.key.toLowerCase().includes(search.toLowerCase())
    );

  // NOTE: Sort the fields based on the index
  const withSort = (fields: VisualizationField[]) =>
    fields.sort((a, b) => a.idx - b.idx);

  // NOTE: For sorting the axis fields
  const handleSort = ({ over, active, collisions }: DragEndEvent) => {
    const axis =
      collisions?.filter((c) => inputIds.includes(c.id as InputAction))[0].id ||
      "";
    const axisFields = axis === "x-axis" ? xAxis : yAxis;
    const setAxisFields = axis === "x-axis" ? setXAxis : setYAxis;

    // NOTE: Sortable for the axis fields only
    if (inputIds.includes(axis as InputAction)) {
      const oldIndex = axisFields.findIndex(({ key }) => key === active.id);
      const newIndex = axisFields.findIndex(({ key }) => key === over?.id);

      return setAxisFields(arrayMove(axisFields, oldIndex, newIndex));
    }
  };

  const prepare = (event: DragEndEvent) => {
    const { over, active, collisions } = event;

    // NOTE: To know which field is dragged
    const fieldAction = active?.id;

    // NOTE: To know where the field is dragged to
    const moveTo =
      (collisions?.filter((c) => inputIds.includes(c.id as InputAction))?.[0]
        ?.id as Action) || "fields";

    // NOTE: To know where the field is dragged from
    const moveFrom = ((): Action => {
      if (!active.data.current?.sortable) return "fields";
      if (xAxis.find((x) => x.key === active.id)) return "x-axis";
      if (yAxis.find((y) => y.key === active.id)) return "y-axis";
      return "fields";
    })();

    // NOTE: To know if the field is dragged to the same axis
    const isSorting = moveTo === moveFrom;

    // NOTE: To get the field object
    const item = searchedFields.find(
      (field) => field.key === fieldAction
    ) as VisualizationField;

    // NOTE: To know the field id that is dragged over
    const overId = moveTo === "fields" ? undefined : over?.id;

    return { moveTo, moveFrom, isSorting, item, fieldAction, overId };
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { moveTo, moveFrom, item, fieldAction, overId, isSorting } =
      prepare(event);

    if (fieldAction === overId) return;
    if (moveTo === "fields" && moveFrom === "fields") return;

    if (moveTo === "x-axis") {
      // NOTE: Limit the x-axis to 2 fields
      if (xAxis.length === 2 && !isSorting) return;

      if (moveFrom === "y-axis") {
        const tempField = yAxis.find((y) => y.key === fieldAction);
        setYAxis(yAxis.filter((y) => y.key !== tempField?.key));

        if (overId !== "x-axis") {
          const newXAxis = [...xAxis, tempField] as VisualizationField[];
          const oldIndex = newXAxis.findIndex(({ key }) => key === fieldAction);
          const newIndex = newXAxis.findIndex(({ key }) => key === overId);

          setXAxis(arrayMove(newXAxis, oldIndex, newIndex));
        }
      }

      if (moveFrom === "x-axis") handleSort(event);
      else {
        let newItem = item;

        // NOTE: In case the field is dragged from the y-axis
        if (!newItem)
          newItem = yAxis.find(
            (y) => y.key === fieldAction
          ) as VisualizationField;
        setXAxis([...xAxis, newItem]);
      }
    }

    if (moveTo === "y-axis") {
      // NOTE: Limit the y-axis to 1 field
      if (yAxis.length === 1 && !isSorting) return;

      if (moveFrom === "x-axis") {
        const tempField = xAxis.find((x) => x.key === fieldAction);
        setXAxis(xAxis.filter((x) => x.key !== tempField?.key));

        if (overId !== "x-axis") {
          const newYAxis = [...yAxis, tempField] as VisualizationField[];
          const oldIndex = newYAxis.findIndex(({ key }) => key === fieldAction);
          const newIndex = newYAxis.findIndex(({ key }) => key === overId);

          setYAxis(arrayMove(newYAxis, oldIndex, newIndex));
        }
      }

      if (moveFrom === "y-axis") handleSort(event);
      else {
        let newItem = item;

        // NOTE: In case the field is dragged from the y-axis
        if (!newItem)
          newItem = xAxis.find(
            (x) => x.key === fieldAction
          ) as VisualizationField;
        setYAxis([...yAxis, newItem]);
      }
    }

    // NOTE: Incase the field is dragged over the xAxis or yAxis
    if (overId) {
      // NOTE: Remove the field from the searched fields
      setSearchedFields(
        withSort(searchedFields.filter((f) => f.key !== fieldAction))
      );

      // NOTE: Remove the field from the all fields
      setAllFields(withSort(allFields.filter((f) => f.key !== fieldAction)));
    }

    // NOTE: Incase the field is dragged back to the field list or outside the droppable area
    if (!overId && !item) {
      const tempField = [...xAxis, ...yAxis].find((f) => f.key === fieldAction);

      if (tempField) {
        setXAxis(xAxis.filter((x) => x.key !== tempField.key));
        setYAxis(yAxis.filter((y) => y.key !== tempField.key));

        // NOTE: Add the field back to the searched fields
        setSearchedFields(
          withSort(withSearchFilter([...searchedFields, tempField]))
        );

        // NOTE: Add the field back to the all fields
        setAllFields(withSort([...allFields, tempField]));
      }
    }
  };

  // NOTE: Reset the axis fields
  const handleResetAxis = (keyAxis: string) => {
    const axisFields = keyAxis === "x-axis" ? xAxis : yAxis;
    const setAxisFields = keyAxis === "x-axis" ? setXAxis : setYAxis;

    const _allFields = withSort([...allFields, ...axisFields]);
    const _searchedFields = withSort(
      withSearchFilter([...searchedFields, ...axisFields])
    );

    setAllFields(_allFields);
    setSearchedFields(_searchedFields);
    setAxisFields([]);
  };

  return { handleDragEnd, handleResetAxis };
};
