import { Icon } from "leaflet";
import L from "leaflet";
import React, { FC, useMemo } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

type TabMapProps = {
  dataSource: any[];
};

export const TabMap: FC<TabMapProps> = ({ dataSource }) => {
  const getLatitude = (data: any) => {
    return Number(data?.latitude || data?.Latitude || data?.lat || data?.Lat);
  };

  const getLongitude = (data: any) => {
    return Number(data?.longitude || data?.Longitude || data?.lon || data?.Lon);
  };

  const defaultMarkerCenter = [12.9236, 100.8824];

  const foodIcon = new Icon({
    iconUrl: "/map-marker.png",
    iconSize: [25, 35], // size of the icon
    // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    // popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  const { markerCenter, markerList } = useMemo(() => {
    const markerList = dataSource.map((data) => {
      const latitude = getLatitude(data);
      const longitude = getLongitude(data);

      return {
        ...data,
        ...(latitude && longitude && { marker: [latitude, longitude] }),
      };
    });

    const firstItem = dataSource[0];
    const markerCenter = [
      getLatitude(firstItem) || defaultMarkerCenter[0],
      getLongitude(firstItem) || defaultMarkerCenter[1],
    ];

    return { markerCenter, markerList };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  return (
    <div>
      <MapContainer
        center={markerCenter as never}
        zoom={15}
        scrollWheelZoom
        preferCanvas
        renderer={L.canvas()}
        style={{ height: "536px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {markerList.map((item, idx) => {
          const marker = item.marker;
          delete item.marker;
          const keys = Object.keys(item);

          if (!marker) return null;

          return (
            <Marker key={idx} position={marker as never} icon={foodIcon}>
              <Popup minWidth={316}>
                <div className="grid grid-cols-2">
                  {keys.map((key) => {
                    return (
                      <React.Fragment key={`${key}-key`}>
                        <span key={`${key}-key`} className="font-bold">
                          {key}
                        </span>

                        <span key={`${key}-val`} className="">
                          {item[key as any]}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
};
