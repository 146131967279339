import clsx from "clsx";
import React, { FC } from "react";
import { FaHome } from "react-icons/fa";

const baseUrl = window.__env__.BASE_CKAN_URL;

type BreadcrumbProps = {
  id: string;
  name: string;
  code: string;
};

export const Breadcrumb: FC<BreadcrumbProps> = ({ id, name, code }) => {
  const items = [
    { label: name, url: `${baseUrl}/dataset/${code}/resource/${id}` },
    { label: "Visualization" },
  ];

  return (
    <div
      className={clsx(
        "flex items-center px-4 m-auto gap-2 pt-[15px] mb-4",
        "ckan-md:w-[750px] ckan-lg:w-[970px] ckan-xl:w-[1180px] "
      )}
    >
      <a className="cursor-pointer" href={baseUrl}>
        <FaHome className="w-[1rem] mb-1" />
      </a>

      {items.map((item, idx) => {
        return (
          <React.Fragment key={`${idx}-${item.label}`}>
            {"/"}
            <a
              key={`${idx}-${item.label}`}
              href={item.url}
              className={clsx(
                item.url && "hover:underline",
                idx === 0 && "font-semibold"
              )}
            >
              {item.label}
            </a>
          </React.Fragment>
        );
      })}
    </div>
  );
};
