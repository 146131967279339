import { FC } from "react";
import { Droppable } from "../../dnd/Droppable";
import { VisualizationField } from "../type";
import { DragButton } from "./DragButton";

type InputAxisProps = {
  label: string;
  axisKey: string;
  axisFields: VisualizationField[];
  onResetAxis: (axisKey: string) => void;
  hasSelectAggregate?: boolean;
  onChangeAggregate?: (
    axisKey: string,
    fieldKey: string,
    aggregateType: string
  ) => void;
};

export const InputAxis: FC<InputAxisProps> = ({
  label,
  axisKey,
  axisFields,
  onResetAxis,
  hasSelectAggregate,
  onChangeAggregate,
}) => {
  return (
    <div className="">
      <h1>{label}:</h1>

      <div className="flex items-center gap-4">
        <Droppable
          key={axisKey}
          id={axisKey}
          className="w-full h-11 flex items-center px-2 py-1 gap-2"
          overClassName=""
          items={axisFields}
        >
          {axisFields.map(({ key, type, aggregateType }) => (
            <DragButton
              key={key}
              dragKey={key}
              type={type}
              aggregateType={aggregateType}
              width="fit"
              hasSelectAggregate={hasSelectAggregate}
              onChangeAggregate={(axisKey, aggregateType) =>
                onChangeAggregate?.(axisKey, key, aggregateType)
              }
            />
          ))}
        </Droppable>

        <button
          className="bg-zinc-300 h-full items-center px-2 py-1 flex rounded-md"
          onClick={() => onResetAxis(axisKey)}
        >
          Reset
        </button>
      </div>
    </div>
  );
};
