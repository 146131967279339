import { useRef, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

export const ToggleLang = () => {
  const items = [
    { lang: "th", flag: "/icons/flag-th.png", text: "ไทย" },
    { lang: "en", flag: "/icons/flag-us.png", text: "English" },
  ];

  const { i18n } = useTranslation();
  const popupRef = useRef<HTMLDivElement>(null);
  const [cookie, setCookie, deleteCookie] = useCookies(["user_lang"]);

  const [open, setOpen] = useState(false);

  useClickOutside(popupRef, () => setOpen(false));

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    setOpen(false);
    deleteCookie("user_lang");
    setCookie("user_lang", lang);
    window.location.reload();
  };

  return (
    <div className="relative">
      <div
        className="h-[40px] flex items-center gap-[6px] px-[10px] hover:bg-[var(--sky-primary-hover)] cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <img
          src={cookie?.user_lang === "th" ? items[0].flag : items[1].flag}
          alt="flag"
          width={24}
        />
        <p className="text-white">
          {cookie?.user_lang === "th" ? items[0].text : items[1].text}
        </p>
      </div>

      {open && (
        <div
          ref={popupRef}
          className="absolute top-full left-0 min-w-[10rem] bg-white shadow-lg border mt-1 rounded-lg py-2"
        >
          {items.map((item) => (
            <div
              key={item.lang}
              onClick={() => changeLang(item.lang)}
              className="flex items-center gap-[6px] px-[10px] hover:bg-[var(--sky-primary-hover)] cursor-pointer text-[var(--sky-primary-active)] hover:text-white"
            >
              <img src={item.flag} alt="flag" width={24} />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
